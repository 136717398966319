import React from 'react'
import PropTypes from 'prop-types'
import { Stars } from './styles'
import { FRAME_PRODUCT_HANDLE, CAL_BUY_LINK } from '../../utils/constants'

const SCRIPT_URL = '//staticw2.yotpo.com/cWK7IeBzmis9dBLQJ4k06IFMkwi6wtXYgA0Ks4Lw/widget.js'

const LINK_DESTINATION = {
  7073751498804: `/products/${FRAME_PRODUCT_HANDLE}#review`,
  6863425175604: `${CAL_BUY_LINK}#review`,
}

const DEFAULT_PRODUCT_ID = '7073751498804'

class YotpoStars extends React.Component {
  componentDidMount() {
    const existingScript = document.querySelectorAll(`script[src='${SCRIPT_URL}']`)
    if (existingScript.length > 0) {
      try {
        yotpo.refreshWidgets() // eslint-disable-line no-undef
      } catch (error) {
        console.log(error)
      }
    } else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = SCRIPT_URL
      script.async = true
      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(script, firstScript)
    }
  }

  render() {
    const { color, size, textColor, linkDestination } = this.props
    let { productId } = this.props
    productId = productId || DEFAULT_PRODUCT_ID
    const page = linkDestination || LINK_DESTINATION[productId]
    return (
      <Stars
        to={`${page}`}
        color={color || '#fdc36d'}
        textColor={textColor || '#444444'}
        size={size || 18}
        className="yotpo bottomLine"
        data-product-id={productId}
      />
    )
  }
}

YotpoStars.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.number,
  productId: PropTypes.string,
  linkDestination: PropTypes.string,
}

export default YotpoStars
