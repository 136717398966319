import React from 'react'
import PropTypes from 'prop-types'

import { MediumHeader } from '../../utils/styles/global-styles'
import {
  PressContainer,
  PressRow,
  RowTopOnMobile,
  RowBottomOnMobile,
  LogoContainer,
} from '../Press/styles'

const containerFor = (source) => (
  <LogoContainer>
    <a href={source.link} rel="noopener noreferrer" target="_blank">
      <img width={100} src={source.image} alt={source.publication} />
    </a>
  </LogoContainer>
)

const DynamicPress = ({ press }) => (
  <PressContainer>
    <MediumHeader>AS FEATURED IN</MediumHeader>
    <PressRow>
      <RowTopOnMobile>
        {!!press[0] && containerFor(press[0])}
        {!!press[1] && containerFor(press[1])}
      </RowTopOnMobile>
      <RowBottomOnMobile>
        {!!press[2] && containerFor(press[2])}
        {!!press[3] && containerFor(press[3])}
      </RowBottomOnMobile>
    </PressRow>
  </PressContainer>
)

DynamicPress.propTypes = {
  press: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      image: PropTypes.string,
      publication: PropTypes.string,
    })
  ),
}

export default DynamicPress
