import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { MediumHeader } from '../DigitalSteps/styles'

export const PressContainer = styled.div`
  max-width: 1020px;
  padding: ${(props) => (props.smallerPadding ? '50px 0px 20px' : '50px 0px 80px')};
  margin: 0 auto;
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.smallerPadding ? '20px 0px 0px' : '20px 0 50px')};
  }
`

export const PressRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-basis: 100%;
  max-width: 800px;
  margin: 50px auto 0px;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
  }
  @media (max-width: 800px) {
    display: block;
    text-align: center;
    max-width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
`

export const logoDividerCss = css`
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    width: 75%;
  }
`

export const RowTopOnMobile = styled.div`
  ${logoDividerCss}
  > * {
    margin-right: 3rem;
  }
  @media (max-width: 800px) {
    margin-bottom: 2rem;
    > * {
      margin: 0 0.5rem 1rem;
    }
    > :first-child {
      margin-right: 3rem;
    }
  }
`

export const RowBottomOnMobile = styled.div`
  ${logoDividerCss}
  > :first-child {
    margin-right: 3rem;
  }
  @media (max-width: 800px) {
    > :first-child {
      margin: 0;
      margin-right: 3rem;
    }
  }
`

export const LogoContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const PressGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4rem;
  grid-template-rows: auto;
  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: 10rem 10rem;
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem 3rem;
    justify-content: center;
  }
`
export const Link = styled.a`
  outline: none;
  border: 0;
  a:hover,
  a:active,
  a:focus {
    outline: none;
    border: 0;
  }
`
export const Header = styled.p`
  text-align: center;
  font-family: 'filsonProBook';
  font-size: 18px;
  line-height: 26px;
  @media (max-width: ${breakpoints.m}px) {
    max-width: 260px;
    margin: 15px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 26px;
    max-width: 250px;
  }
`
export const Border = styled.hr`
  border-top: 1px solid rgba(207, 207, 207, 0.5);
  margin-top: 40px;
  max-width: 800px;
  color: rgba(207, 207, 207, 0.5);
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
  }
  @media (max-width: 800px) {
    max-width: 75%;
  }
`
export const BumpLogo = styled.object`
  width: 100%;
  pointer-events: none;
`
export const BumpLink = styled.a`
  display: block;
  cursor: pointer;
`
export const DigitalHeader = styled(MediumHeader)`
  width: 525px;
  margin: auto;
  text-align: center;
  @media (max-width: 770px) {
    width: 280px;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: auto;
  }
`
export const DigitalLogoContainer = styled.div`
  width: 130px;
  @media (max-width: ${breakpoints.l}px) {
    width: 100px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 75px;
  }
`
export const DigitalPressRow = styled(PressRow)`
  @media (max-width: 800px) {
    display: flex;
    text-align: center;
    max-width: 90%;
    margin: 40px auto 0px;
    @media (max-width: ${breakpoints.l}px) {
      width: 600px;
    }
    @media (max-width: 800px) {
      width: 600px;
    }
    @media (max-width: ${breakpoints.s}px) {
      width: 330px;
      justify-content: space-between;
      > div:first-child {
        width: 60px;
      }
      > div:nth-child(2) {
        width: 47px;
      }
      > div:nth-child(3) {
        width: 48px;
      }
      > div:nth-child(4) {
        width: 60px;
      }
    }
  }
`
