import styled from 'styled-components'
import { Link } from 'gatsby'

export const Stars = styled(Link)`
  text-decoration: none;
  .yotpo-sr-bottom-line-text {
    font-family: 'FilsonProBook' !important;
    cursor: pointer !important;
  }
`
